import { COMPANY_NOT_ONBOARDED } from '~/assets/strings';
import { HttpError } from '~/errors/httpError';
import { NotOnboardedError } from '~/errors/notOnboardedError';
import { companyGateway } from '~/gateways/companyInfoGateway';
import { UseCase } from '../framework/useCase';
import { MainAppState, mainAppState } from '../state/mainAppState';

const TEST_DOMAIN = 'hogwarts.with.magicdoor.com';

export abstract class MagicUseCase extends UseCase<MainAppState> {
  protected isAppStateInitialized = () => {
    return !!(this.getState() && this.getState()?.user.companyInfo);
  };

  protected async initializeState(): Promise<MainAppState> {
    try {
      const domain = import.meta.env.DEV ? TEST_DOMAIN : window.location.hostname;
      mainAppState.user.companyInfo = await companyGateway.getCompanyInfo(domain);
    } catch (error: any) {
      if (error instanceof HttpError && error.getStatusCode() === 404) {
        throw new NotOnboardedError(COMPANY_NOT_ONBOARDED);
      }
    }
    return mainAppState;
  }
}
